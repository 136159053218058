/**
 * Set Formik conditions values based on selected item conditions
 * @param group - The group to update in Formik (carrierparty, disposerparty, locationorgin, locationdestination etc)
 * @param conditions - The selected item conditions
 * @param setFormikValue - Formik's setFieldValue function
 */
export const setLocationConditions = (group, conditions, setFormikValue) => {
    if (conditions && conditions.length > 0) {
        conditions.forEach(item => {
            const fieldName = `${group}.conditions.${item.name}`;
            const fieldValue = item.value === '1';
            setFormikValue(fieldName, fieldValue);
        });
    }else{
        const fieldName = `${group}.conditions`
        setFormikValue(fieldName, null);
    }
};